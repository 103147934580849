<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <search-field @search="search" :title="'Search'"></search-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                offset-md="5"
                offset-lg="5"
                offset-xl="5"
                order="first"
                order-sm="first"
                order-md="last"
                order-lg="last"
                order-xl="last"
              >
                <div class="float-right">
                  <fab-button
                    v-if="$moduleAccess('add')"
                    @click="openAddDialog()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-plus'"
                    :tooltipSpan="'Add'"
                  ></fab-button
                  >&nbsp;
                  <fab-button
                    @click="getData()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-refresh'"
                    :tooltipSpan="'Refresh'"
                  ></fab-button>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <v-btn text @click="openFilterExpansion">
                      <v-icon>{{
                        openFilter ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}</v-icon>
                      <span>&nbsp;&nbsp;Filter {{ title }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="
                          font-size: 14px;
                          margin-top: 14px;
                          display: inline-block;
                        "
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="
                          font-size: 14px;
                          max-width: 60px;
                          display: inline-block;
                          height: 34px;
                        "
                        :items="page"
                        class="grey--text mx-2"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px; display: inline-block"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="
                            font-size: 14px;
                            margin-top: 14px;
                            display: inline-block;
                          "
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="
                            font-size: 14px;
                            max-width: 60px;
                            display: inline-block;
                            height: 34px;
                          "
                          :items="page"
                          class="grey--text mx-2"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <transition name="fade">
          <v-card v-if="openFilter" class="grey lighten-3 elevation-0">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                  <v-select
                    v-model="filterCompany"
                    :items="filterCompanyItems"
                    label="By Company"
                    solo
                    dense
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </transition>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center">
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td class="text-uppercase">{{ item.imei_no }}</td>
                <!-- <td class="text-uppercase">{{ item.serial_no }}</td> -->
                <!-- <td class="text-uppercase">{{ item.sim_card_no }}</td> -->
                <td class="text-uppercase">{{ item.unit_no }}</td>
                <td class="text-uppercase">
                  {{
                    item.tracking_gps_device_type
                      ? item.tracking_gps_device_type.name
                      : "--"
                  }}
                </td>
                <td class="text-uppercase">
                  {{ item.company ? item.company.name : "--" }}
                </td>
                <td class="text-uppercase">
                  {{ item.subcompany ? item.subcompany.name : "--" }}
                </td>
                <td class="text-uppercase">
                  {{ item.vehicle ? item.vehicle.plate_no : "--" }}
                </td>
                <td class="text-center text-no-wrap">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue"
                        icon
                        @click="openViewDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('edit')" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="teal"
                        icon
                        @click="openEditDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    v-if="item.company && $moduleAccess('unassign company')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        @click="openUnassignCompanyDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-domain</v-icon>
                      </v-btn>
                    </template>
                    <span>Unassign Company</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    v-if="
                      item.subcompany && $moduleAccess('unassign subcompany')
                    "
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        @click="openUnassignSubCompanyDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-domain</v-icon>
                      </v-btn>
                    </template>
                    <span>Unassign Sub-Company</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    v-if="item.vehicle && $moduleAccess('unassign vehicle')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        @click="openUnassignVehicleDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-truck</v-icon>
                      </v-btn>
                    </template>
                    <span>Unassign Vehicle</span>
                  </v-tooltip>
                  <v-tooltip top v-if="$moduleAccess('delete')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        @click="openDeleteDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the View & Edit GPS Device Dialog ----->

    <full-width-dialog
      :dialog="fullWidthDialog"
      :title="dialogType + ' ' + title"
      :color="dialogColor"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : ''
      "
      :saveButton="saveButton"
      dark
      @saveDialog="
        dialogType === 'Add'
          ? validateAddDialog()
          : dialogType === 'Edit'
          ? validateEditDialog()
          : ''
      "
      @closeDialog="
        dialogType === 'Add'
          ? closeAddDialog()
          : dialogType === 'View'
          ? closeViewDialog()
          : dialogType === 'Edit'
          ? closeEditDialog()
          : ''
      "
    >
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4"></v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <details-container
              :data="data"
              :readonly="readonly"
              :deviceTypes="deviceTypes"
              :companies="companies"
              ref="detailsContainer"
              @openSaveDialog="
                dialogType === 'Add'
                  ? openSaveAddDialog()
                  : dialogType === 'Edit'
                  ? openSaveEditDialog()
                  : ''
              "
            ></details-container>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4"></v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the Confirmation of Adding Page/s ---->

    <mini-dialog
      :dialog="saveAddDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveAddDialog()"
      @confirmDialog="addGPSDevice()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Editing Page/s ---->

    <mini-dialog
      :dialog="saveEditDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveEditDialog()"
      @confirmDialog="editGPSDevice()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Delete Page/s ---->

    <mini-dialog
      :dialog="deleteDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Delete'"
      :cardText="'Are you sure you want to delete this?'"
      @closeDialog="closeDeleteDialog()"
      @confirmDialog="deleteGPSDevice()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Unassign Company ---->

    <mini-dialog
      :dialog="unassignCompanyDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Unassign'"
      :cardText="'Are you sure you want to unassign this?'"
      @closeDialog="closeUnassignCompanyDialog()"
      @confirmDialog="unassignCompany()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Unassign SubCompany ---->

    <mini-dialog
      :dialog="unassignSubCompanyDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Unassign'"
      :cardText="'Are you sure you want to unassign this?'"
      @closeDialog="closeUnassignSubCompanyDialog()"
      @confirmDialog="unassignSubCompany()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Unassign Vehicle ---->

    <mini-dialog
      :dialog="unassignVehicleDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Unassign'"
      :cardText="'Are you sure you want to unassign this?'"
      @closeDialog="closeUnassignVehicleDialog()"
      @confirmDialog="unassignVehicle()"
    ></mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/Services";
import DetailsContainer from "@/components/details_container/GPSDeviceDetailsContainer";

export default {
  components: {
    DetailsContainer,
  },
  data: () => {
    return {
      title: "GPS Device",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        {
          text: "IMEI No.",
          align: "left",
          sortable: false,
        },
        // {
        //   text: "Serial No.",
        //   align: "left",
        //   sortable: false,
        // },
        // {
        //   text: "SIM No.",
        //   align: "left",
        //   sortable: false,
        // },
        {
          text: "Unit No.",
          align: "left",
          sortable: false,
        },
        {
          text: "Device Type",
          align: "left",
          sortable: false,
        },
        {
          text: "Assigned Company",
          align: "left",
          sortable: false,
        },
        {
          text: "Assigned Sub-Company",
          align: "left",
          sortable: false,
        },
        {
          text: "Assigned Vehicle",
          align: "left",
          sortable: false,
        },
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      data: {},
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      confirmLoading: false,
      fullWidthDialog: false,
      fullWidthDialogLoading: false,
      dialogType: "",
      dialogColor: "",
      saveButton: false,
      readonly: false,
      saveAddDialog: false,
      saveEditDialog: false,
      deleteDialog: false,
      deviceTypes: [],
      companies: [],
      unassignCompanyDialog: false,
      unassignSubCompanyDialog: false,
      unassignVehicleDialog: false,
      openFilter: false,
      filterCompany: null,
      filterCompanyItems: [],
    };
  },
  created: function () {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  watch: {
    filterCompany: async function () {
      this.queryParams.company_id = this.filterCompany;
      await this.getData();
    },
  },
  methods: {
    async search(data) {
      this.queryParams.search = data;
      await this.getData();
    },
    async getData() {
      this.$store.dispatch("startLoading");

      try {
        let response = (await Services.getGPSDevices(this.queryParams)).data;

        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    async openFilterExpansion() {
      this.openFilter = await !this.openFilter;
      this.filterCompanyItems = await this.setFilterCompanies();
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    async openAddDialog() {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Add";
      this.dialogColor = "amber";
      this.saveButton = true;
      this.readonly = false;

      const deviceTypes = await this.setGPSDeviceTypes();

      const companies = await this.setCompanies();

      this.deviceTypes = await Promise.all(
        deviceTypes.data.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.companies = await Promise.all(
        companies.data.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.data.id = null;
      this.data.tracking_gps_device_type_id = null;
      this.data.company_id = null;
      this.data.sub_company_id = null;
      this.data.imei_no = "";
      // this.data.serial_no = "";
      this.data.sim_card_no = "";
      this.data.unit_no = "";

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeAddDialog() {
      this.fullWidthDialog = false;
    },
    validateAddDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveAddDialog() {
      this.saveAddDialog = true;
    },
    closeSaveAddDialog() {
      this.saveAddDialog = false;
    },
    async addGPSDevice() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        tracking_gps_device_type_id: data.tracking_gps_device_type_id,
        company_id: data.company_id,
        sub_company_id: data.sub_company_id,
        imei_no: data.imei_no,
        // serial_no: data.serial_no,
        sim_card_no: data.sim_card_no,
        unit_no: data.unit_no,
      };

      try {
        const response = await Services.addGPSDevice(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async openViewDialog(data) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "View";
      this.dialogColor = "blue";
      this.saveButton = false;
      this.readonly = true;

      const deviceTypes = await this.setGPSDeviceTypes();

      const filterCompanies = await this.setCompanies();

      const companies = filterCompanies.data.filter((item) => {
        // return !item.company_settings.suspended;
          item.company_settings && item.company_settings.suspended == false
      });

      this.deviceTypes = await Promise.all(
        deviceTypes.data.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.companies = await Promise.all(
        companies.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.data.id = data.id;
      this.data.tracking_gps_device_type_id = data.tracking_gps_device_type
        ? data.tracking_gps_device_type.id
        : null;
      this.data.company_id = data.company ? data.company.id : null;
      this.data.sub_company_id = data.subcompany ? data.subcompany.id : null;
      this.data.imei_no = data.imei_no ? data.imei_no : null;
      this.data.serial_no = data.serial_no
        ? data.serial_no.toUpperCase()
        : null;
      this.data.sim_card_no = data.sim_card_no
        ? data.sim_card_no.toUpperCase()
        : null;
      this.data.unit_no = data.unit_no ? data.unit_no.toUpperCase() : null;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeViewDialog() {
      this.fullWidthDialog = false;
    },
    async openEditDialog(data) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Edit";
      this.dialogColor = "teal";
      this.saveButton = true;
      this.readonly = false;

      const deviceTypes = await this.setGPSDeviceTypes();

      const filterCompanies = await this.setCompanies();

      const companies = filterCompanies.data.filter((item) => {
        return (
          item.company_settings && item.company_settings.suspended == false
        );
      });

      this.deviceTypes = await Promise.all(
        deviceTypes.data.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.companies = await Promise.all(
        companies.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );

      this.data.id = data.id;
      this.data.tracking_gps_device_type_id = data.tracking_gps_device_type
        ? data.tracking_gps_device_type.id
        : null;
      this.data.company_id = data.company ? data.company.id : null;
      this.data.sub_company_id = data.subcompany ? data.subcompany.id : null;
      this.data.imei_no = data.imei_no ? data.imei_no : null;
      // this.data.serial_no = data.serial_no
      //   ? data.serial_no.toUpperCase()
      //   : null;
      this.data.sim_card_no = data.sim_card_no
        ? data.sim_card_no.toUpperCase()
        : null;
      this.data.unit_no = data.unit_no ? data.unit_no.toUpperCase() : null;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeEditDialog() {
      this.fullWidthDialog = false;
    },
    validateEditDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveEditDialog() {
      this.saveEditDialog = true;
    },
    closeSaveEditDialog() {
      this.saveEditDialog = false;
    },
    async editGPSDevice() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        tracking_gps_device_type_id: data.tracking_gps_device_type_id,
        company_id: data.company_id,
        sub_company_id: data.sub_company_id,
        imei_no: data.imei_no,
        // serial_no: data.serial_no,
        sim_card_no: data.sim_card_no,
        unit_no: data.unit_no,
      };

      try {
        const response = await Services.editGPSDevice(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been edited!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.data.id = item.id;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async deleteGPSDevice() {
      this.confirmLoading = true;

      try {
        const response = await Services.deleteGPSDevice(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    openUnassignCompanyDialog(item) {
      this.unassignCompanyDialog = true;
      this.data.id = item.id;
    },
    closeUnassignCompanyDialog() {
      this.unassignCompanyDialog = false;
    },
    async unassignCompany() {
      this.confirmLoading = true;

      try {
        const response = await Services.unassignCompany(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "GPS Device has been unassigned!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    openUnassignSubCompanyDialog(item) {
      this.unassignSubCompanyDialog = true;
      this.data.id = item.id;
    },
    closeUnassignSubCompanyDialog() {
      this.unassignSubCompanyDialog = false;
    },
    async unassignSubCompany() {
      this.confirmLoading = true;

      try {
        const response = await Services.unassignSubCompany(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "GPS Device has been unassigned!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    openUnassignVehicleDialog(item) {
      this.unassignVehicleDialog = true;
      this.data.id = item.id;
    },
    closeUnassignVehicleDialog() {
      this.unassignVehicleDialog = false;
    },
    async unassignVehicle() {
      this.confirmLoading = true;

      try {
        const response = await Services.unassignVehicle(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "GPS Device has been unassigned!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    closeAllDialog() {
      this.closeAddDialog();
      this.closeSaveAddDialog();
      this.closeViewDialog();
      this.closeEditDialog();
      this.closeSaveEditDialog();
      this.closeDeleteDialog();
      this.closeUnassignCompanyDialog();
      this.closeUnassignVehicleDialog();
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    async setGPSDeviceTypes() {
      const response = await Services.getGPSDeviceTypes({});

      return response.data;
    },
    async setCompanies() {
      const response = await Services.getCompanies({
        approved: true,
      });

      return response.data;
    },
    async setFilterCompanies() {
      let data;

      try {
        const response = await Services.getCompanies({
          approved: true,
        });

        const returnedData = response.data.data.filter((item) => {
          return !item.company_settings.suspended;
        });

        data = await Promise.all(
          returnedData.map((item) => {
            return {
              text: item.name.toUpperCase(),
              value: item.id,
            };
          })
        );
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }
      return data;
    },
  },
};
</script>

<style></style>
