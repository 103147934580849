<template>
  <v-card>
    <v-card-title style="background-color: #EEEEEE;"
      >GPS Device Details</v-card-title
    >
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="formGPSDevice">
        <v-row>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
          <v-col cols="12" sm="12" md="10" lg="10" xl="10">
            <!-- <v-text-field
              v-model="data.serial_no"
              label="Serial No"
              required
              :rules="[rules.required]"
              :readonly="readonly"
            ></v-text-field> -->
            <v-text-field
              v-model="data.imei_no"
              label="IMEI No"
              required
              :rules="[rules.required]"
              :readonly="readonly"
            ></v-text-field>
            <v-text-field
              v-model="data.sim_card_no"
              label="SIM Card No"
              required
              :rules="[rules.required]"
              :readonly="readonly"
            ></v-text-field>
            <v-text-field
              v-model="data.unit_no"
              label="Unit No"
              required
              :rules="[rules.required]"
              :readonly="readonly"
            ></v-text-field>
            <v-select
              v-model="data.tracking_gps_device_type_id"
              label="Device Type"
              required
              :rules="[rules.required]"
              :readonly="readonly"
              :items="deviceTypes"
              clearable
            ></v-select>
            <v-select
              v-model="companyId"
              label="Assign Company"
              required
              :readonly="readonly"
              :items="companies"
              clearable
            ></v-select>
            <v-select
              v-model="subCompanyId"
              label="Assign Sub-Company"
              required
              :readonly="readonly"
              :items="subcompanies"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Services from "@/services/CompanyServices";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    deviceTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    companies: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      subcompanies: [],
      companyId: null,
      subCompanyId: null,
    };
  },
  created: async function() {
    this.companyId = this.data.company_id;
    this.subCompanyId = this.data.sub_company_id;

    if (!this.companyId) {
      const filterSubCompanies = await this.setSubCompanies();

      this.subcompanies = await Promise.all(
        filterSubCompanies.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );
    }
  },
  watch: {
    companyId: async function() {
      const filterSubCompanies = await this.setSubCompanies();

      this.subcompanies = await Promise.all(
        filterSubCompanies.map((item) => {
          return {
            text: item.name.toUpperCase(),
            value: item.id,
          };
        })
      );
    },
  },
  computed: {
    computedData() {
      return {
        tracking_gps_device_type_id: this.data.tracking_gps_device_type_id,
        imei_no: this.data.imei_no,
        // serial_no: this.data.serial_no,
        sim_card_no: this.data.sim_card_no,
        unit_no: this.data.unit_no,
        company_id: this.companyId,
        sub_company_id: this.subCompanyId,
      };
    },
  },
  methods: {
    validateForm() {
      if (this.$refs.formGPSDevice.validate()) {
        this.$emit("openSaveDialog");
      }
    },
    async setSubCompanies() {
      let subCompanyParameter = {};

      if (this.companyId) {
        subCompanyParameter.company_id = this.companyId;
      }

      const response = await Services.getSubCompanies(subCompanyParameter);

      return response.data.data;
    },
  },
};
</script>

<style></style>
